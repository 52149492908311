<template>
  <main>
    <b-container>
      <b-row class="project-details__heading d-flex align-center pt-3 mt-3">
        <b-row cols="12">
          <h1 class="font-bold mainColor mt-3 my-3">{{ Slug.title }}</h1>
        </b-row>
      </b-row>
    </b-container>
    <b-container fluid>
      
     <!-- <v-row class=" project-details__heading d-flex justify-right align-center pr-4  pt-3 mt-3 ">  <h4 class="font-bold mainColor mt-3 my-3">{{ Slug.title }}</h4></v-row> -->
      <thumbs-gallery
        class=""
        :conf-photos="MediaSlider"
      />
     
      <!-- <b-row>
        <b-carousel
          class="ParentCarousel"
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          fade
          indicators
          background="#ababab"
          style="text-shadow: 1px 1px 2px #333;"
        >
          <b-carousel-slide
            class="Slider"
            v-for="Slide in MediaSlider"
            :key="Slide.id"
            :caption="slide.title"
            :img-src="Slide.path"
          ></b-carousel-slide>
        </b-carousel>
      </b-row>-->

      <!-- Slides with img slot -->
      <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
      <!-- <b-carousel-slide>
        <template v-slot:img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="https://picsum.photos/1024/480/?image=55"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>-->
      <!-- <b-row class="mx-auto ">
      <b-col >
        <video-player
          class="video-player-box"
          ref="videoPlayer"
          :options="playerOptions"
          :playsinline="true"
        ></video-player>
      </b-col>
      </b-row>-->
    </b-container>
    <b-container>
      <b-row class="blog mt-0">
        <div class="blog__limit py-4 font-regular" v-html="Slug.description"></div>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import { IndexData } from "@/helpers/apiMethods";
import "video.js/dist/video-js.css";

// import { videoPlayer } from "vue-video-player";

export default {
  components: {
    ThumbsGallery: () => import("@/components/ThumbsGallery")
    // videoPlayer
  },
  data() {
    return {
      slide: 0,
      MediaSlider: {},
      playerOptions: {
        // videojs options
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src:
              "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
          }
        ],
        poster: "/static/images/author.jpg"
      },
      Slug: {},
      noPublisher: "No Publisher"
    };
  },
  created() {
    this.fetchSlugData();
  },

  methods: {
    async fetchSlugData() {
      try {
        const {
          data: { news }
        } = await IndexData({ reqName: `news/${this.$route.params.slug}` });
        this.Slug = news;
        this.MediaSlider = news.media;
    
      } catch (err) {
        console.log(err);
      }
    },
    IndexNew(slug) {
     
      this.$router.push({ path: `/blog/${slug}` });
    }
  }
};
</script>

<style scoped>
/* .PageWidth {
  width: 100vw;
  margin-top: 10%;
  margin-bottom: 5%;
} */
.Slider {
  width: 100%;
  height: 100%;
}
.ParentCarousel {
  height: 80vh;
  object-fit: fill;
}
.project-details__heading {
  margin-top: 10% !important;
  color: #060e49 !important;
}
.blog__limit {
  font-size: 18px;
  width: 95%;
}
</style>
